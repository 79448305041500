import React from "react"
import desarrolloCss from "../../css/desarrollo.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Web from "../../components/images/desarrolloWeb/web"
import MenuDesarrollo from "../../components/menuDesarrollo/menuDesarrollo"
import LogoBlanco from "../../images/logo-blanco.svg"
import { Link } from "gatsby"

const WebDevelopment = () => (
  <>
    <div className={desarrolloCss.background}>
      <div className={desarrolloCss.gradient}></div>
    </div>
    <Web></Web>
    <Link to="/" className={desarrolloCss.logoBlanco}>
      <img src={LogoBlanco} alt="Logotipo de engrane version blanca"/>
    </Link>
    <MenuDesarrollo></MenuDesarrollo>
    <div className={desarrolloCss.container}>
      <Layout siteTitle="Desarrollo">
        <SEO title="Desarrollo Web" />
        <div className={desarrolloCss.contenido}>
          <h1 className={desarrolloCss.titulo}>
            be your
            <br />
            web
          </h1>
          <p className={desarrolloCss.text}>
            Somos especialistas en desarrollar soluciones de software para
            empresas.
          </p>
          <p className={desarrolloCss.text}>
            Tal vez tu estas buscando un administrador de empleados, o un
            control de la información de tu empresa, tal vez una manera
            innovadora de resguardar tus contraseñas importantes, o cualquier
            otra cosa.
          </p>
          <p className={desarrolloCss.text}>
            Sin importar la idea o problema que tengas, nosotros podremos
            desarrollarlo y asesorarte en el camino.
          </p>
        </div>
      </Layout>
    </div>
    <Link className={desarrolloCss.productos}>PRODUCTOS <span>&nbsp;&nbsp;&nbsp;→</span></Link>
  </>
)

export default WebDevelopment
